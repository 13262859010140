import { Injectable } from '@angular/core';
import { GetResult, Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(key: string, value: any) {
    return await Storage.set({
      key,
      value,
    });
  }

  public async get(key: string): Promise<GetResult> {
    return await Storage.get({
      key,
    });
  }

  public async remove(key) {
    return await Storage.remove({
      key,
    });
  }
}
