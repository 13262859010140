import { IUser } from './../interfaces/user';
import { StorageService } from 'src/app/services/storage.service';
import { Injectable } from '@angular/core';

import {
  Firestore,
  addDoc,
  collection,
  collectionData,
  doc,
  docData,
  deleteDoc,
  updateDoc,
  DocumentReference,
  setDoc,
  DocumentData,
  QueryConstraint,
  where,
  query,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private firestore: Firestore, private storage: StorageService) {}

  getFeaturedUsers(): Observable<IUser[]> {
    const collectionRef = collection(this.firestore, 'users');
    const collectionQuery: QueryConstraint[] = [
      where('isFeatured', '==', true),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<IUser[]>;
  }

  async addUser(user: IUser) {
    if (user) {
      const userRef = doc(this.firestore, `users/${user.id}`);
      const result = await setDoc(userRef, user);
      return result;
    }
  }

  async updateUser(userId: string, updateUser: any) {
    if (userId) {
      const userRef = doc(this.firestore, `users/${userId}`);
      const result = await updateDoc(userRef, updateUser);
      return result;
    }
  }

  getUserById(id: string) {
    const userRef = doc(this.firestore, `users/${id}`);
    return docData(userRef, { idField: 'id' }) as Observable<IUser>;
  }

  getUserByUsername(username: string) {
    const collectionRef = collection(this.firestore, 'users');
    const collectionQuery: QueryConstraint[] = [
      where('username', '==', username),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    const collection$ = collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<IUser[]>;
    const userProfile = collection$.pipe(
      first(),
      map((value) => value[0])
    );
    return userProfile;
  }

  async getUserProfile(): Promise<IUser> {
    const { value } = await this.storage.get('profile');
    if (value) {
      return JSON.parse(value) as IUser;
    } else {
      return null;
    }
  }
}
