import { Injectable, inject } from '@angular/core';
import {
  GoogleAuthProvider,
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
  signInWithCredential,
} from '@angular/fire/auth';
import { Router, UrlTree } from '@angular/router';
import { StorageService } from './storage.service';
import { ProfileService } from './profile.service';
import { UserService } from './user.service';
import { ICheckAuthStateForLogin } from '../interfaces';
import { Platform } from '@ionic/angular';
import { User, getRedirectResult, signInWithRedirect } from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private userService: UserService,
    private auth: Auth,
    private storage: StorageService,
    private router: Router,
    private platform: Platform
  ) {}

  public static logoutUser() {
    signOut(inject(Auth));
  }

  currentUser() {
    return this.auth.currentUser;
  }

  async register({ email, password }) {
    try {
      const user = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      return user;
    } catch (e) {
      return e;
    }
  }

  async login({ email, password }) {
    try {
      const user = await signInWithEmailAndPassword(this.auth, email, password);
      console.log(
        '🚀 ~ file: auth.service.ts ~ line 39 ~ AuthService ~ login ~ user',
        user
      );
      return user;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async googleSignin() {
    const provider = new GoogleAuthProvider();
    if (this.platform.is('cordova')) {
      const credential = await signInWithPopup(this.auth, provider);
      return credential.user;
    } else {
      signInWithRedirect(this.auth, provider);
      // This will trigger a full page redirect away from your app
      await signInWithRedirect(this.auth, provider);
      // After returning from the redirect when your app initializes you can obtain the result
      const result = await getRedirectResult(this.auth);
      if (result) {
        console.log(
          '🚀 ~ file: auth.service.ts:82 ~ AuthService ~ googleSignin ~ result:',
          result
        );
        // This is the signed-in user
        const user = result.user;
        // This gives you a Facebook Access Token.
        const credential = GoogleAuthProvider.credentialFromResult(result);

        console.log(
          '🚀 ~ file: auth.service.ts:86 ~ AuthService ~ googleSignin ~ credential:',
          credential
        );
        const credential1 = signInWithCredential(this.auth, credential);
        const userCredential = await signInWithCredential(
          this.auth,
          credential
        );
        return userCredential.user;
      }
    }
  }

  async twitterSignin() {
    const provider = new TwitterAuthProvider();
    const credential = await signInWithPopup(this.auth, provider);
    return credential.user;
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  async logout() {
    await signOut(this.auth);
    await this.storage.remove('profile');
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }

  getUserProfile() {
    const user = this.currentUser();
    console.log(
      '🚀 ~ file: auth.service.ts:81 ~ AuthService ~ getUserProfile ~ user:',
      user
    );
    if (user) {
      return this.userService.getUserById(this.auth.currentUser.uid);
    }
    return null;
  }

  checkAuthState(): Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          resolve(true);
        } else {
          resolve(this.router.parseUrl('/login'));
        }
      });
    });
  }

  authState(): Promise<User> {
    return new Promise((resolve) => {
      onAuthStateChanged(this.auth, (user) => {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      });
    });
  }

  checkAuthStateForLogin(): Promise<ICheckAuthStateForLogin> {
    return new Promise((resolve) => {
      const userProfile$ = this.getUserProfile();
      userProfile$.subscribe((userProfile) => {
        console.log(
          '🚀 ~ file: auth.service.ts:108 ~ AuthService ~ userProfile$.subscribe ~ userProfile:',
          userProfile
        );
        if (userProfile) {
          resolve({ status: true, userProfile, userProfile$ });
        } else {
          resolve({ status: false, userProfile: null, userProfile$: null });
        }
      });
    });
  }
}
