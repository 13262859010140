import { StorageService } from 'src/app/services/storage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUser } from '../interfaces';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private userProfile$: BehaviorSubject<IUser> = new BehaviorSubject(null);

  constructor(private storage: StorageService, private router: Router) {
    this.init();
  }

  async init() {
    const { value } = await this.storage.get('profile');
    if (value) {
      const userProfile = JSON.parse(value);
      this.setProfile(userProfile);
    } else {
      this.setProfile(null);
    }
  }

  getProfile(): Observable<IUser> {
    return this.userProfile$.asObservable();
  }

  async setProfile(userProfile: IUser) {
    await this.storage.set('profile', JSON.stringify(userProfile));
    this.userProfile$.next(userProfile);
  }
}
