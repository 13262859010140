import { Action, createReducer, on } from '@ngrx/store';

import { loadBalance } from './balance.actions';
import { IBalance } from '../interfaces/balance';

export interface BalanceState {
  balance: IBalance;
}

export const initialBalanceState: BalanceState = {
  balance: null,
};

const balanceReducer = createReducer(
  initialBalanceState,
  on(loadBalance, (state, { balance }) => ({
    ...state,
    balance,
  }))
);

export function reducer(state: BalanceState | undefined, action: Action) {
  return balanceReducer(state, action);
}
