import { Action, createReducer, on } from '@ngrx/store';
import { IUser } from '../interfaces';
import {
  loadUserProfile,
  loadUserProfileFailure,
  loadUserProfileSuccess,
} from './user.actions';

export interface UserProfileState {
  userProfile: IUser;
  loading: boolean;
  error: string | null;
}

export const initialUserProfileState: UserProfileState = {
  userProfile: null,
  loading: false,
  error: null,
};

const userProfileReducer = createReducer(
  initialUserProfileState,
  on(loadUserProfile, (state, { userProfile }) => ({
    ...state,
    userProfile,
    loading: true,
  })),
  on(loadUserProfileSuccess, (state, { userProfile }) => ({
    ...state,
    userProfile,
    loading: false,
  })),
  on(loadUserProfileFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
);

export function reducer(state: UserProfileState | undefined, action: Action) {
  return userProfileReducer(state, action);
}
