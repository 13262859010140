/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConsoleLogService {
  constructor() {}

  disableConsoleInProduction(): void {
    if (environment.production) {
      console.log('%cStop!', 'color: red; font-size: 60px');
      console.log(
        // eslint-disable-next-line max-len
        `%c🚨 WARNING: UNAUTHORIZED ACCESS DETECTED. YOUR ACTIONS ARE BEING MONITORED AND LOGGED. ANY ATTEMPT TO BREACH SECURITY OR GAIN UNAUTHORIZED ACCESS TO THIS SYSTEM WILL BE PROSECUTED TO THE FULLEST EXTENT OF THE LAW. CEASE YOUR ACTIVITIES IMMEDIATELY AND CONTACT THE SYSTEM ADMINISTRATOR IF YOU BELIEVE THIS WARNING HAS BEEN TRIGGERED IN ERROR`,
        'color: black; font-size: 20px'
      );
      console.warn(
        // eslint-disable-next-line max-len
        `🚨 This is a browser feature intended for developers. If someone told you to copy-paste something here to enable a Tipsee feature or "hack" someone's account, it is a scam and will give them access to your Tipsee account.`
      );
      console.log = (): void => {};
      console.debug = (): void => {};
      console.warn = (): void => {};
      console.info = (): void => {};
    }
  }
}
