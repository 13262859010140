// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'tip-see',
    appId: '1:514875797469:web:f96e76e046cde45a7c9034',
    storageBucket: 'tip-see.appspot.com',
    locationId: 'asia-southeast1',
    apiKey: 'AIzaSyBFWzj0Z-DHGQ9wylfcu4b1Vmgxn6GkUE8',
    authDomain: 'tip-see.firebaseapp.com',
    messagingSenderId: '514875797469',
  },
  useEmulators: true,
  production: false,
  libraryId: '121918',
  collectionId: '8172362a-7926-4f12-85cb-adc240eef476',
  apiKey: '636ec537-56ee-486b-9c243d72df93-6f62-4c52',
  expirationTime: Math.floor(Date.now() / 1000) + 10800000,
  accessKey: '636ec537-56ee-486b-9c243d72df93-6f62-4c52',
  storageAccessKey: 'ade05819-30eb-45d1-908ee2180735-3827-44e9',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
