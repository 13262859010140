import { ActionReducerMap } from '@ngrx/store';

import {
  UserProfileState,
  reducer as userProfileReducer,
} from './user.reducer';

import { BalanceState, reducer as balanceReducer } from './balance.reducer';

export interface AppState {
  userProfile: UserProfileState;
  balance: BalanceState;
}

export const reducers: ActionReducerMap<AppState> = {
  userProfile: userProfileReducer,
  balance: balanceReducer,
};
