import { ImageUploadService } from 'src/app/services/image-service.service';
import { ConsoleLogService } from './services/console-log.service';
import { ProfileService } from './services/profile.service';
import { Component, OnInit } from '@angular/core';
import { Auth, getAuth, onAuthStateChanged, User } from '@angular/fire/auth';
import { IUser } from './interfaces/user';
import { StorageService } from './services/storage.service';
import { UserService } from './services/user.service';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { DatabaseService } from './services/database.service';
import { AppState } from './ngrx/app.state';
import { Store } from '@ngrx/store';
import { loadBalance } from './ngrx/balance.actions';
import { loadUserProfile } from './ngrx/user.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser: User = null;
  prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

  constructor(
    private userService: UserService,
    private profile: ProfileService,
    // private updateService: UpdateService,
    private platform: Platform,
    private authService: AuthService,
    private consoleLogService: ConsoleLogService,
    private imageUploadService: ImageUploadService,
    private auth: Auth,
    private database: DatabaseService,
    private store: Store<AppState>
  ) {
    // this.platform.ready().then(() => {
    //   if (this.platform.is('android')) {
    //     this.updateService.checkUpdate();
    //   }
    // });
    // this.imageUploadService.readFilesFromStorage('tipsee').then((files) => {
    //   console.log(
    //     '🚀 ~ file: app.component.ts ~ line 82 ~ AppComponent ~ this.imageUploadService.readFilesFromStorage ~ files',
    //     files
    //   );
    // });
  }

  loadSharedData() {
    // this.authService.authState().then((user) => {
    //   if (user) {
    //     // Get Balance and save it to store
    //     this.database.getUserBalance(user.uid).then(async (res) => {
    //       console.log(
    //         '🚀 ~ file: app.component.ts:57 ~ AppComponent ~ this.database.getUserBalance ~ res:',
    //         res
    //       );
    //       const balance = res.data;
    //       this.store.dispatch(loadBalance({ balance }));
    //     });
    //     // Get User Profile and save it to store
    //     this.userService.getUserById(user.uid).subscribe((userProfile) => {
    //       console.log(
    //         '🚀 ~ file: app.component.ts:63 ~ AppComponent ~ this.userService.getUserById ~ userProfile:',
    //         userProfile
    //       );
    //       if (userProfile) {
    //         if (this.platform.is('android')) {
    //           this.profile.setProfile(userProfile);
    //         } else {
    //           this.store.dispatch(loadUserProfile({ userProfile }));
    //         }
    //       }
    //     });
    //   }
    // });
  }
  ngOnInit() {
    // this.loadSharedData();
    this.checkDarkMode();
    this.consoleLogService.disableConsoleInProduction();
  }

  runColorMode(fn) {
    if (!window.matchMedia) {
      return;
    }

    const query = window.matchMedia('(prefers-color-scheme: dark)');

    fn(query.matches);

    query.addEventListener('change', (event) => fn(event.matches));
  }

  checkDarkMode() {
    this.runColorMode((isDarkMode) => {
      if (isDarkMode) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    });
  }

  async getUserProfile() {
    // check if storage is empty
    this.profile.getProfile().subscribe((profile) => {
      if (!profile) {
        try {
          const auth = getAuth();
          onAuthStateChanged(auth, (user) => {
            if (user) {
              this.currentUser = user;
              this.userService
                .getUserById(user.uid)
                .subscribe(async (userProfile) => {
                  if (userProfile) {
                    await this.profile.setProfile(userProfile);
                    console.log(
                      '🚀 ~ file: app.component.ts ~ line 40 ~ AppComponent ~ .subscribe ~ userProfile',
                      userProfile
                    );
                  } else {
                    this.authService.logout();
                  }
                });
            } else {
              this.authService.logout();
            }
          });
        } catch (error) {
          console.log(
            '🚀 ~ file: profile.page.ts ~ line 44 ~ ProfilePage ~ getUserProfile ~ error',
            error
          );
        }
      }
    });
  }
}
