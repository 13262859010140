import { createAction, props } from '@ngrx/store';
import { IUser } from '../interfaces';

export const loadUserProfile = createAction(
  '[User Profile] Load User Profile',
  props<{ userProfile: IUser }>()
);
export const loadUserProfileSuccess = createAction(
  '[User Profile] Load User Profile Success',
  props<{ userProfile: IUser }>()
);

export const loadUserProfileFailure = createAction(
  '[User Profile] Load User Profile Failure',
  props<{ error: string }>()
);
