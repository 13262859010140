import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { doc, Firestore, updateDoc } from '@angular/fire/firestore';
import {
  getDownloadURL,
  ref,
  Storage,
  updateMetadata,
  uploadString,
} from '@angular/fire/storage';
import { Photo } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import * as tus from 'tus-js-client';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  constructor(
    private platform: Platform,
    private auth: Auth,
    private firestore: Firestore,
    private storage: Storage
  ) {}

  async uploadDepositRequestAttachment(
    photo: Photo,
    collectionName: string,
    fileName: string,
    id: string
  ) {
    const user = this.auth.currentUser;
    const path = `uploads/${user.uid}/${fileName}.${photo.format}`;
    const storageRef = ref(this.storage, path);

    try {
      await uploadString(storageRef, photo.base64String, 'base64');
      const attachment = await getDownloadURL(storageRef);

      const userDocRef = doc(this.firestore, `${collectionName}/${id}`);
      await updateDoc(userDocRef, {
        attachment,
      });
      return true;
    } catch (e) {
      console.log(
        '🚀 ~ file: avatar.service.ts ~ line 52 ~ AvatarService ~ uploadImage ~ e',
        e
      );
      return null;
    }
  }

  async hash(str: string) {
    const utf8 = new TextEncoder().encode(str);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

  // Uploads an image to firebase storage
  // async uploadPhoto(
  //   photo: Photo,
  //   filename: string,
  //   username: string = 'storage'
  // ): Promise<string> {
  //   return new Promise(async (resolve, reject) => {
  //     const user = this.auth.currentUser;
  //     const path = `images/${username}/${filename}`;
  //     const storageRef = ref(this.storage, path);

  //     try {
  //       await uploadString(storageRef, photo.base64String, 'base64');
  //       const downloadUrl = await getDownloadURL(storageRef);
  //       resolve(downloadUrl);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }

  // Read files from firebase storage and return an array of urls
  // async readFilesFromStorage(username) {
  //   const user = this.auth.currentUser;
  //   const path = `images/${username}/resized`;
  //   const storageRef = ref(this.storage, path);
  //   const files = await list(storageRef);
  //   const urls = [];
  //   for (const file of files.items) {
  //     const url = await getDownloadURL(file);
  //     urls.push(url);
  //   }
  //   return urls;
  // }

  // Uploads an image to firebase storage
  async uploadPhoto(
    photo: Photo,
    filename: string,
    username: string = 'storage'
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const user = this.auth.currentUser;
      const path = `images/${username}/${filename}`;
      const storageRef = ref(this.storage, path);

      try {
        await uploadString(storageRef, photo.base64String, 'base64', {
          contentType: `image/${photo.format}`,
          // customMetadata: { ['firebaseStorageDownloadTokens']: null },
        });

        // if (photo.format === 'jpeg' || photo.format === 'png') {
        //   await updateMetadata(storageRef, {
        //     contentType: `image/${photo.format}`,
        //   });
        // } else if (photo.format === 'mp4') {
        //   await updateMetadata(storageRef, {
        //     contentType: `video/${photo.format}`,
        //   });
        // }
        const downloadUrl = await getDownloadURL(storageRef);
        resolve(downloadUrl);
      } catch (e) {
        console.log(
          '🚀 ~ file: avatar.service.ts ~ line 52 ~ AvatarService ~ uploadImage ~ e',
          e
        );
        reject('');
      }
    });
  }

  // Uploads an image to firebase storage
  async uploadVideo(photo: Photo, filename: string): Promise<string> {
    console.log(
      '🚀 ~ file: image-service.service.ts:55 ~ ImageUploadService ~ uploadPhoto ~ filename:',
      filename,
      photo.base64String
    );
    return new Promise(async (resolve, reject) => {
      const user = this.auth.currentUser;
      const path = `uploads/${user.uid}/${filename}`;
      const storageRef = ref(this.storage, path);

      try {
        // await uploadString(storageRef, photo.base64String, 'base64');
        // const downloadUrl = await getDownloadURL(storageRef);
        // resolve(downloadUrl);

        // Bunny CDN
        const options = {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/*+json',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            AccessKey: environment.accessKey,
          },
          body:
            '{"title":"' +
            filename +
            '","collectionId":"' +
            environment.collectionId +
            '","thumbnailTime":1}',
        };

        fetch('https://video.bunnycdn.com/library/121918/videos', options)
          .then((response) => response.json())
          .then(async (response) => {
            console.log(response.guid);
            const videoId = response.guid;
            const libraryId = environment.libraryId;
            const collectionId = environment.collectionId;
            const apiKey = environment.apiKey;
            const expirationTime = Math.floor(Date.now() / 1000) + 10800000;
            const presignedSignature = await this.hash(
              libraryId + apiKey + expirationTime + videoId
            );

            const base64Response = await fetch(
              `data:video/mp4;base64,${photo.base64String}`
            );
            const blob = await base64Response.blob();
            const file = new File([blob], filename, { type: 'video/mp4' });
            // Create a new tus upload
            const upload = new tus.Upload(file, {
              endpoint: 'https://video.bunnycdn.com/tusupload',
              retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
              headers: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                AuthorizationSignature: presignedSignature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
                // eslint-disable-next-line @typescript-eslint/naming-convention
                AuthorizationExpire: expirationTime.toString(), // Expiration time as in the signature,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                VideoId: videoId, // The guid of a previously created video object through the Create Video API call
                // eslint-disable-next-line @typescript-eslint/naming-convention
                LibraryId: libraryId,
              },
              metadata: {
                filetype: 'video/mp4',
                title: filename,
                collection: collectionId,
              },
              onError: (error) => {
                console.log('Failed because: ' + error);
              },
              onProgress: (bytesUploaded, bytesTotal) => {
                const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(
                  2
                );
                console.log(bytesUploaded, bytesTotal, percentage + '%');
              },
              onSuccess: () => {
                console.log('Download %s from %s', file, upload.url);
              },
            });

            // Check if there are any previous uploads to continue.
            upload.findPreviousUploads().then((previousUploads) => {
              // Found previous uploads so we select the first one.
              if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0]);
              }

              // Start the upload
              upload.start();
            });
          })
          .catch((err) => console.error(err));

        reject('');
        // End Bunny CDN
      } catch (e) {
        console.log(
          '🚀 ~ file: avatar.service.ts ~ line 52 ~ AvatarService ~ uploadImage ~ e',
          e
        );
        reject('');
      }
    });
  }

  async readAsBase64(photo: Photo) {
    if (this.platform.is('hybrid')) {
      const file = await Filesystem.readFile({
        path: photo.path,
      });
      return `data:image/${photo.format};base64,${file.data}`;
    } else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(photo.webPath);
      const blob = await response.blob();

      return (await this.convertBlobToBase64(blob)) as string;
    }
  }

  convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  base64ToImage(dataURI) {
    const fileDate = dataURI.split(',');
    // const mime = fileDate[0].match(/:(.*?);/)[1];
    const byteString = atob(fileDate[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    return blob;
  }
}
