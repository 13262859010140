import { ProfileService } from 'src/app/services/profile.service';
import { Participant, UnlockedMedia } from './../interfaces/conversation';
import { IMessage } from './../interfaces/message';
import {
  BlockedAccount,
  Favorite,
  Feed,
  ISubscription,
  IUser,
} from 'src/app/interfaces';
import { IComment } from 'src/app/interfaces';
import { Injectable, inject } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import {
  Firestore,
  doc,
  setDoc,
  updateDoc,
  docData,
  collection,
  addDoc,
  where,
  query,
  QueryConstraint,
  collectionData,
  orderBy,
  deleteDoc,
  Timestamp,
  DocumentReference,
} from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { Conversation, Message } from '../interfaces/conversation';
import { Transaction } from '../interfaces/transaction';
import { UnlockedPost } from '../interfaces/unlocked-posts';
import { WithdrawalRequest } from '../interfaces/withdrawal-request';
import { AuthService } from './auth.service';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { IBalance, IBalanceResponse } from '../interfaces/balance';
@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private functions: Functions = inject(Functions);

  userId: string;

  totalDeposit = new Subject<number>();

  totalSpending = new Subject<number>();

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private authService: AuthService,
    private profile: ProfileService
  ) {}

  getAllFeeds(): Observable<Feed[]> {
    const collectionRef = collection(this.firestore, 'posts');
    const collectionQuery: QueryConstraint[] = [
      where('status', '==', 'active'),
      orderBy('created', 'desc'),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<Feed[]>;
  }

  getAllFeedsByUser(userId: string): Observable<Feed[]> {
    const collectionRef = collection(this.firestore, 'posts');
    const collectionQuery: QueryConstraint[] = [
      where('status', '==', 'active'),
      where('userId', '==', userId),
      orderBy('created', 'desc'),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<Feed[]>;
  }

  async addToFavorite(feed: Feed) {
    if (this.auth.currentUser.uid) {
      // add to users favorite
      const favRef = collection(this.firestore, `/favorites`);
      const newFav: any = {
        userId: this.auth.currentUser.uid,
        feedId: feed.id,
        contentCreatorId: feed.userId,
      };
      const result2 = addDoc(favRef, newFav);

      return [result2];
    }
  }

  async removeToFavorite(favId: string) {
    if (this.auth.currentUser.uid) {
      const favRef = doc(this.firestore, `/favorites/${favId}`);
      const result2 = deleteDoc(favRef);

      return [result2];
    }
  }

  getAllMyFavorites(): Observable<Favorite[]> {
    if (this.auth.currentUser.uid) {
      const collectionRef = collection(this.firestore, '/favorites');
      const collectionQuery: QueryConstraint[] = [
        where('userId', '==', this.auth.currentUser.uid),
      ];
      const collectionRefQuery = query(collectionRef, ...collectionQuery);
      return collectionData(collectionRefQuery, {
        idField: 'id',
      }) as Observable<Favorite[]>;
    }
  }

  getAllMyBlockedAccounts(): Observable<BlockedAccount[]> {
    if (this.auth.currentUser.uid) {
      const collectionRef = collection(this.firestore, '/blockedAccounts');
      const collectionQuery: QueryConstraint[] = [
        where('userId', '==', this.auth.currentUser.uid),
      ];
      const collectionRefQuery = query(collectionRef, ...collectionQuery);
      return collectionData(collectionRefQuery, {
        idField: 'id',
      }) as Observable<BlockedAccount[]>;
    }
  }

  getAllMyBookmarks(): Observable<Feed[]> {
    if (this.auth.currentUser.uid) {
      const collectionRef = collection(this.firestore, '/bookmarks');
      const collectionQuery: QueryConstraint[] = [
        where('userId', '==', this.auth.currentUser.uid),
      ];
      const collectionRefQuery = query(collectionRef, ...collectionQuery);
      return collectionData(collectionRefQuery, {
        idField: 'id',
      }) as Observable<Feed[]>;
    }
  }

  getAllFeedFavorites(
    collectionName: string,
    feed: Feed
  ): Observable<Favorite[]> {
    if (this.auth.currentUser.uid) {
      const collectionRef = collection(this.firestore, collectionName);
      const collectionQuery: QueryConstraint[] = [
        where('feedId', '==', feed.id),
      ];
      const collectionRefQuery = query(collectionRef, ...collectionQuery);
      return collectionData(collectionRefQuery, {
        idField: 'id',
      }) as Observable<Favorite[]>;
    }
  }

  // Comments
  getAllComments(feedId: string): Observable<IComment[]> {
    const collectionRef = collection(this.firestore, 'comments');
    const collectionQuery: QueryConstraint[] = [
      where('feedId', '==', feedId),
      orderBy('created', 'desc'),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<IComment[]>;
  }

  // Content Creators
  getAllContentCreators(): Observable<IUser[]> {
    const collectionRef = collection(this.firestore, 'users');
    const collectionQuery: QueryConstraint[] = [
      where('isContentCreator', '==', true),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<IUser[]>;
  }

  // Conversation
  updateConversation(data: any) {
    const collectionName = '/conversations';
    const collectionRef = doc(this.firestore, `${collectionName}/${data.id}`);
    return updateDoc(collectionRef, data);
  }

  async getConversationId(
    sender: Participant,
    receiver: Participant
  ): Promise<Conversation> {
    return new Promise(async (resolve, reject) => {
      let currentRoom: any = null;
      const collectionRef = collection(this.firestore, 'conversations');
      const collectionQuery: QueryConstraint[] = [
        where('userId', 'in', [sender.id, receiver.id]),
        where('participantIds', 'array-contains', receiver.id),
        // where('recentMessage', '!=', '')
      ];
      const collectionRefQuery = query(collectionRef, ...collectionQuery);
      const conversation$ = collectionData(collectionRefQuery, {
        idField: 'id',
      });

      conversation$.subscribe((conversations: Conversation[]) => {
        console.log(
          '🚀 ~ file: database.service.ts ~ line 181 ~ DatabaseService ~ conversation$.subscribe ~ conversation',
          conversations
        );

        // If the conversation doesnt exist, create one
        if (conversations.length == 0) {
          // create new conversation
          const newConversation: Conversation = {
            participantIds: [sender.id, receiver.id],
            recentMessage: '',
            recentSender: sender,
            updatedAt: Timestamp.fromDate(new Date()),
            createdAt: Timestamp.fromDate(new Date()),
            sender: sender,
            receiver: receiver,
            unread: false,
            unreadCount: 0,
          };
          currentRoom = this.addDoc('/conversations', newConversation);
          resolve(currentRoom);
        } else {
          // return conversation;
          resolve(conversations[0]);
        }
      });
    });
  }

  getConversationByUser(participant: Participant): Observable<Conversation[]> {
    let currentRoom: any = null;
    const collectionRef = collection(this.firestore, 'conversations');
    const collectionQuery: QueryConstraint[] = [
      where('participantIds', 'array-contains', participant.id),
      where('recentMessage', '!=', ''),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<Conversation[]>;
  }

  // Messages

  getMessagesByConversationId(conversationId: string): Observable<Message[]> {
    const collectionRef = collection(this.firestore, 'messages');
    const collectionQuery: QueryConstraint[] = [
      where('conversationId', '==', conversationId),
      orderBy('createdAt', 'asc'),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<Message[]>;
  }

  // Transactions

  getMySpentTransactions(userId: string): Observable<Transaction[]> {
    const collectionRef = collection(this.firestore, 'transactions');
    const collectionQuery: QueryConstraint[] = [
      where('senderId', '==', userId),
      orderBy('createdAt', 'desc'),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<Transaction[]>;
  }

  getMyReceivedTransactions(userId: string): Observable<Transaction[]> {
    const collectionRef = collection(this.firestore, 'transactions');
    const collectionQuery: QueryConstraint[] = [
      where('receiverId', '==', userId),
      orderBy('createdAt', 'desc'),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<Transaction[]>;
  }

  getUserBalance(userId: string): Promise<IBalanceResponse> {
    this.functions.region = 'asia-southeast1';
    return httpsCallable(
      this.functions,
      'getBalance'
    )({
      userId: userId,
    }) as unknown as Promise<IBalanceResponse>;
  }

  // Withdrawal Requests
  getMyWithdrawalRequests(contentCreatorId: string) {
    const collectionRef = collection(this.firestore, 'withdrawalRequests');
    const collectionQuery: QueryConstraint[] = [
      where('userId', '==', contentCreatorId),
      orderBy('createdAt', 'desc'),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<WithdrawalRequest[]>;
  }

  // Check Subscription

  async checkSubscription(subscriberId: string, contentCreatorId: string) {
    const collectionRef = collection(this.firestore, 'subscriptions');
    const collectionQuery: QueryConstraint[] = [
      where('subscriberId', '==', subscriberId),
      where('contentCreatorId', '==', contentCreatorId),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    });
  }

  getMySubscriptions(subscriberId: string): Observable<ISubscription[]> {
    const collectionRef = collection(this.firestore, 'subscriptions');
    const collectionQuery: QueryConstraint[] = [
      where('subscriberId', '==', subscriberId),
      orderBy('createdAt', 'desc'),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<ISubscription[]>;
  }

  getMySubscribers(contentCreatorId: string): Observable<ISubscription[]> {
    const collectionRef = collection(this.firestore, 'subscriptions');
    const collectionQuery: QueryConstraint[] = [
      where('contentCreatorId', '==', contentCreatorId),
      orderBy('createdAt', 'desc'),
    ];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<ISubscription[]>;
  }

  // Unlocked posts
  getMyUnlockedPosts(userId: string): Observable<UnlockedPost[]> {
    const collectionRef = collection(this.firestore, 'unlockedPosts');
    const collectionQuery: QueryConstraint[] = [where('userId', '==', userId)];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<UnlockedPost[]>;
  }

  // Unlocked media
  getMyUnlockedMedia(userId: string): Observable<UnlockedMedia[]> {
    const collectionRef = collection(this.firestore, 'unlockedMedia');
    const collectionQuery: QueryConstraint[] = [where('userId', '==', userId)];
    const collectionRefQuery = query(collectionRef, ...collectionQuery);
    return collectionData(collectionRefQuery, {
      idField: 'id',
    }) as Observable<UnlockedMedia[]>;
  }

  delete(collectionName: string, id: string) {
    const collectionRef = doc(this.firestore, `${collectionName}/${id}`);
    return deleteDoc(collectionRef);
  }

  async addDoc(collectionName: string, data: any) {
    if (this.auth.currentUser.uid) {
      const collectionRef = collection(this.firestore, `${collectionName}`);
      data.userId = this.auth.currentUser.uid;
      const result = await addDoc(collectionRef, data);
      return result;
    }
  }

  async setDoc(collectionName: string, data: any) {
    if (this.auth.currentUser.uid) {
      const collectionRef = doc(
        this.firestore,
        `${collectionName}/${this.auth.currentUser.uid}`
      );
      const result = await setDoc(collectionRef, data);
      return result;
    }
  }

  async updateDoc(collectionName: string, data: any) {
    if (this.auth.currentUser.uid) {
      const collectionRef = doc(
        this.firestore,
        `${collectionName}/${this.auth.currentUser.uid}`
      );
      const result = await updateDoc(collectionRef, data);
      return result;
    }
  }

  async updateDocById(collectionName: string, id: string, data: any) {
    if (this.auth.currentUser.uid) {
      const collectionRef = doc(this.firestore, `${collectionName}/${id}`);
      const result = await updateDoc(collectionRef, data);
      return result;
    }
  }

  getDocById(collectionName: string, id: string) {
    const collectionRef = doc(this.firestore, `${collectionName}/${id}`);
    return docData(collectionRef, { idField: 'id' });
  }

  getDocByUserId(collectionName: string) {
    if (this.auth.currentUser.uid) {
      const collectionRef = doc(
        this.firestore,
        `${collectionName}/${this.auth.currentUser.uid}`
      );
      return docData(collectionRef) as Observable<Favorite>;
    }
  }
}
