import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const redirectLoggedInToHome = () => redirectLoggedInTo(['tabs/home']);
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./navigation/tabs/tabs.module').then((m) => m.TabsPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./authentication/login/login.module').then(
        (m) => m.LoginPageModule
      ),
    ...canActivate(redirectLoggedInToHome),
  },
  {
    path: 'pre-signup',
    loadChildren: () =>
      import('./authentication/pre-signup/pre-signup.module').then(
        (m) => m.PreSignupPageModule
      ),
  },
  {
    path: 'continue-fan-signup',
    loadChildren: () =>
      import(
        './authentication/continue-fan-signup/continue-fan-signup.module'
      ).then((m) => m.ContinueFanSignupPageModule),
  },
  {
    path: 'fan-signup',
    loadChildren: () =>
      import('./authentication/fan-signup/fan-signup.module').then(
        (m) => m.FanSignupPageModule
      ),
  },
  {
    path: 'model-signup',
    loadChildren: () =>
      import('./authentication/model-signup/model-signup.module').then(
        (m) => m.ModelSignupPageModule
      ),
  },
  {
    path: 'id-verification',
    loadChildren: () =>
      import('./authentication/id-verification/id-verification.module').then(
        (m) => m.IdVerificationPageModule
      ),
  },
  {
    path: 'message-detail',
    loadChildren: () =>
      import('./pages/message-detail/message-detail.module').then(
        (m) => m.MessageDetailPageModule
      ),
  },
  {
    path: 'edit-profile',
    loadChildren: () =>
      import('./pages/edit-profile/edit-profile.module').then(
        (m) => m.EditProfilePageModule
      ),
  },
  {
    path: 'verify-email',
    loadChildren: () =>
      import('./authentication/verify-email/verify-email.module').then(
        (m) => m.VerifyEmailPageModule
      ),
  },
  {
    path: 'select-outlet',
    loadChildren: () =>
      import('./pages/settings/select-outlet/select-outlet.module').then(
        (m) => m.SelectOutletPageModule
      ),
  },
  {
    path: 'gcash-outlet',
    loadChildren: () =>
      import('./pages/settings/gcash-outlet/gcash-outlet.module').then(
        (m) => m.GcashOutletPageModule
      ),
  },
  {
    path: 'view-profile',
    loadChildren: () =>
      import('./pages/view-profile/view-profile.module').then(
        (m) => m.ViewProfilePageModule
      ),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/preview-profile/preview-profile.module').then(
        (m) => m.PreviewProfilePageModule
      ),
  },

  {
    path: 'comments',
    loadChildren: () =>
      import('./pages/comments/comments.module').then(
        (m) => m.CommentsPageModule
      ),
  },
  {
    path: 'choose-media',
    loadChildren: () =>
      import('./pages/choose-media/choose-media.module').then(
        (m) => m.ChooseMediaPageModule
      ),
  },
  {
    path: 'subscribe',
    loadChildren: () =>
      import('./pages/subscribe/subscribe.module').then(
        (m) => m.SubscribePageModule
      ),
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('./pages/subscriptions/subscriptions.module').then(
        (m) => m.SubscriptionsPageModule
      ),
  },
  {
    path: 'manage-subscription',
    loadChildren: () =>
      import('./pages/manage-subscription/manage-subscription.module').then(
        (m) => m.ManageSubscriptionPageModule
      ),
  },
  {
    path: 'unlock-post',
    loadChildren: () =>
      import('./pages/unlock-post/unlock-post.module').then(
        (m) => m.UnlockPostPageModule
      ),
  },
  {
    path: 'transaction-history',
    loadChildren: () =>
      import('./pages/transaction-history/transaction-history.module').then(
        (m) => m.TransactionHistoryPageModule
      ),
  },
  {
    path: 'unlocked-posts',
    loadChildren: () =>
      import('./pages/unlocked-posts/unlocked-posts.module').then(
        (m) => m.UnlockedPostsPageModule
      ),
  },
  {
    path: 'send-tip',
    loadChildren: () =>
      import('./pages/send-tip/send-tip.module').then(
        (m) => m.SendTipPageModule
      ),
  },
  {
    path: 'subscribers',
    loadChildren: () =>
      import('./pages/subscribers/subscribers.module').then(
        (m) => m.SubscribersPageModule
      ),
  },

  {
    path: 'subscriber-details',
    loadChildren: () =>
      import('./pages/subscriber-details/subscriber-details.module').then(
        (m) => m.SubscriberDetailsPageModule
      ),
  },
  {
    path: 'transaction-history-creator',
    loadChildren: () =>
      import(
        './pages/transaction-history-creator/transaction-history-creator.module'
      ).then((m) => m.TransactionHistoryCreatorPageModule),
  },
  {
    path: 'withdrawal-requests',
    loadChildren: () =>
      import('./pages/withdrawal-requests/withdrawal-requests.module').then(
        (m) => m.WithdrawalRequestsPageModule
      ),
  },
  {
    path: 'withdrawal-request',
    loadChildren: () =>
      import('./pages/withdrawal-request/withdrawal-request.module').then(
        (m) => m.WithdrawalRequestPageModule
      ),
  },
  {
    path: 'bookmarks',
    loadChildren: () =>
      import('./pages/bookmarks/bookmarks.module').then(
        (m) => m.BookmarksPageModule
      ),
  },

  {
    path: 'report',
    loadChildren: () =>
      import('./pages/report/report.module').then((m) => m.ReportPageModule),
  },
  {
    path: 'choose-amount',
    loadChildren: () =>
      import('./pages/choose-amount/choose-amount.module').then(
        (m) => m.ChooseAmountPageModule
      ),
  },

  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },

  {
    path: 'fan-onboarding',
    loadChildren: () =>
      import('./pages/onboarding/fan-onboarding/fan-onboarding.module').then(
        (m) => m.FanOnboardingPageModule
      ),
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
